import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { FaArrowLeft } from "react-icons/fa"
import Masonry from "react-masonry-css"
import { SRLWrapper } from "simple-react-lightbox"
import Layout from "../../components/core/Layout"
import SEO from "../../components/core/SEO"

type SculpturalWorkProps = {
  data: {
    images: {
      edges: Array<{ node: { childImageSharp: any } }>
    }
  }
}

const MarkGalleryPage: React.FC<SculpturalWorkProps> = ({ data }) => (
  <Layout activePage="gallery">
    <SEO title="Sculptural Work" />
    <p>
      <Link className="gallery-back-button" to="/gallery">
        <FaArrowLeft />
        Back to Gallery
      </Link>
    </p>
    <h1 className="page-title gallery-title">Sculptural Work</h1>
    <div className="divider divider--spacer-bottom" />
    <SRLWrapper>
      <Masonry
        className="masonry-grid"
        columnClassName="masonry-grid__column"
        breakpointCols={{ default: 4, 550: 1, 1000: 2, 1500: 3 }}
      >
        {data.images.edges.map(({ node }) => (
          <GatsbyImage
            image={node.childImageSharp.full}
            style={{ cursor: "pointer" }}
            className="masonry-grid__image"
            alt="Sculptural Work"
          />
        ))}
      </Masonry>
    </SRLWrapper>
  </Layout>
)

export const query = graphql`
  query ImagesForSculpturalGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery/sculptural" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default MarkGalleryPage
